import { Component } from 'nuxt-property-decorator';
import { Context } from '@nuxt/types';
import BinderBase from '@/components/shared/binder-base';
import { parseBinderItemsResponse } from '@/utils/binderFoldersUtils';
import { MetaInfo } from 'vue-meta';

@Component
export default class SharedBookmarkById extends BinderBase {
  head(): MetaInfo {
    const bookmark = this.items && this.items.length > 0 ? this.items[0] : null;
    return {
      title: bookmark != null ? `${bookmark.headingText}(${bookmark.doc.title})の共有ブックマーク` : '',
    };
  }

  async asyncData({
    app: { $repositories, $auth },
    error,
    route,
  }: Context): Promise<Pick<SharedBookmarkById, 'binder' | 'folder' | 'id' | 'items'> | undefined> {
    if (!$auth.permissions.binder) {
      error({ statusCode: 403, message: 'このページは閲覧できません' });
    } else {
      const id = route.params.id;
      const bookmarkId = route.params.bookmark;
      try {
        const { binder, folder, items } = parseBinderItemsResponse(
          await $repositories.binderFolders.getSharedBinderItems(id),
        );
        const filteredItems = items.filter((item) => item.type === 'bookmark' && item.id === bookmarkId);
        if (filteredItems.length < 1) throw new Error('対象のブックマークがレスポンスに含まれていません');
        return { binder, folder, id, items: filteredItems };
      } catch (error) {
        console.error(error);
        throw new Error(
          'ブックマークの読み込みに失敗しました。このブックマークは共有したユーザーが削除した可能性があります',
        );
      }
    }
  }
}
